import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
	
  <header
	className="topHeader"
  >
    <div
      className="header-left"
    >
    
        <a
          href="/"
        >
	
	     <img src={"/images/arena-logo.svg"}
			alt="Arena Pharmaceuticals"
			layout="fixed"
			width={135}
			height={36} />
	     </a>
     
    </div>
	
	<span className="medicalAffairsHeader">MEDICAL AFFAIRS</span>
	<nav><ul><li  id="ibd">IBD Science</li><li id="pipeline">Our Pipeline</li><li id="resources"><a href="/gi/resources/">Resources</a></li><li id="about"><a href="/gi/about-us/">About Us</a></li></ul>
		</nav>
	<div id="menuMask"></div>
	<div id="mobileMenuBtn" ><div id="menubar1"></div><div id="menubar2"></div><div id="menubar3"></div><div id="label1">MENU</div></div>
	<nav id="mobileMenu">
<strong>IBD Science</strong><br /><hr />
<a className="extra-left" href="/gi/ibd-science/mechanisms-of-ibd-focus-on-s1p/">Mechanisms of IBD: Focus on S1P</a><hr/>
	<a className="extra-left" href="/gi/ibd-science/video-library-ibd-perspectives/">Video Library: IBD Perspectives</a><hr/>
	<a className="extra-left" href="/gi/ibd-science/current-research-in-ibd/">Current Research in IBD</a><hr/>
	<a className="extra-left" href="/gi/ibd-science/ibd-treatment-guidelines/">IBD Treatment Guidelines</a>
<strong>Our Pipeline</strong><br /><hr />
	<a className="extra-left" href="/gi/our-pipeline/etrasimod/">Etrasimod</a>
	<hr/><a className="extra-left"  href="/gi/our-pipeline/olorinab/">Olorinab</a>
	<hr/><a className="extra-left" href="/gi/our-pipeline/publications-archive/">Arena Publications Archive</a>
	<hr/><a className="extra-left" href="/gi/our-pipeline/congress-activities/">Congress Activities</a>
<strong><a href="/gi/resources/">Resources</a></strong><br /><hr />
<a className="extra-left" href="/gi/resources/#contact">Contact Arena</a><hr/>
	<a className="extra-left" href="/gi/resources/#connect">Connect With the GI Community</a>
<strong><a href="/gi/about-us/">About Us</a></strong><br /><hr />
</nav>
	</header>	
	
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
