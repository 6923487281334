/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Dropdowns from "./dropdowns"
import "./main.css"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
	  <Dropdowns />
        <main>{children}</main>
        <footer className="mainFooter">
	<div className="left"><p>© 2022 Arena Pharmaceuticals, Inc.<br />Arena Pharmaceuticals and Arena are registered service marks of Arena
          Pharmaceuticals, Inc.<br />All Rights Reserved. 12/2021: MRC--DHF-0001</p>
	  </div>
	  <div className="right"><a href="https://www.arenapharm.com/terms-and-conditions/">Terms and Conditions</a><a href="https://www.arenapharm.com/privacy/privacy-statement/">Privacy Policy</a>    <a href="https://www.arenapharm.com/arena-social-media-community-guidelines">Social Media Community Guidelines</a><a href={"/gi/resources/#contact"}>Contact Us</a>
	  </div>
</footer>
	  <div className="overlay exit-ramp closed"><div className="shadow"></div><div className="box"><img src={'/images/arena-logo.svg'}  width="135" height="36" className="logo" alt="Arena Pharmaceuticals"/><h3>You are now leaving this site and being directed to a site not maintained by Arena.
Arena is not responsible for content or privacy policies on other sites.</h3><img src={'/images/cancel.svg'} alt="cancel" className="cancelBtn" /><a href="" id="exitLink" target="_blank" ><img src={'/images/okay.svg'} alt="ok" className="okayBtn" /></a></div></div>
	  <div className="overlay hcp-dialog closed"><div className="shadow"></div><div className="box"><img src={'/images/arena-logo.svg'}  width="135" height="36" className="logo" alt="Arena Pharmaceuticals" /><h3>THIS INFORMATION IS INTENDED FOR U.S. HEALTHCARE PROFESSIONALS</h3><hr />
	  <div className="row"><div className="b"><img src={'/images/yes.svg'} alt="Yes" className="" id="yesIAmHCP"/></div><div className="c"><h4>I AM A U.S. HEALTHCARE/MEDICAL PROFESSIONAL</h4><p>I acknowledge that I am requesting access to this site, including scientiﬁc and
educational information about Arena and its research and development programs. </p></div></div>
	  <div className="row"><div className="b"><img src={'/images/no.svg'} alt="cancel" className="" id="noIAmNot" /></div><div className="c"><h4>I AM NOT A U.S. HEALTHCARE/MEDICAL PROFESSIONAL </h4></div></div>
	  <div className="legal">This site and all materials are intended for U.S. healthcare professionals as a non-promotional resource for medical and scientific information and non-certified medical education purposes only.<br />
© 2022 Arena Pharmaceuticals, Inc.<br/ >
Arena Pharmaceuticals and Arena are registered service marks of Arena Pharmaceuticals, Inc. <br/>
All Rights Reserved. 12/2021: MRC--DHF-0001</div>
	  </div></div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
