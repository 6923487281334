import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

const Dropdowns = () => (<header className="dropdownMenus">
	<div id="pipelineDropdown" className="hide">
	<a href="/gi/our-pipeline/etrasimod/">Etrasimod</a>
	<hr/><a href="/gi/our-pipeline/olorinab/">Olorinab</a>
	<hr/><a href="/gi/our-pipeline/publications-archive/">Arena Publications Archive</a>
	<hr/><a href="/gi/our-pipeline/congress-activities/">Congress Activities</a>
	</div>
	<div id="ibdDropdown" className="hide"><a href="/gi/ibd-science/mechanisms-of-ibd-focus-on-s1p/">Mechanisms of IBD: Focus on S1P</a><hr/><a href="/gi/ibd-science/video-library-ibd-perspectives/">Video Library: IBD Perspectives</a><hr/><a href="/gi/ibd-science/current-research-in-ibd/">Current Research in IBD</a><hr/><a href="/gi/ibd-science/ibd-treatment-guidelines/">IBD Treatment Guidelines</a></div>
	
			  <div id="resourcesDropdown" className="hide"><a href="/gi/resources/#contact">Contact Arena</a><hr/><a href="/gi/resources/#connect">Connect With the GI Community</a></div></header>
)

Dropdowns.propTypes = {
  siteTitle: PropTypes.string,
}

Dropdowns.defaultProps = {
  siteTitle: ``,
}

export default Dropdowns
